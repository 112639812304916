import { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./styles";

const CryptoCard = ({ img, name, setter }) => {
  const classes = useStyles();
  const handleSetter = () => {
    setter(name);
  };
  return (
    <Grid item xs={2}>
      <Paper
        onClick={handleSetter}
        className={classes.card_paper}
        elevation={3}
      >
        <div className="hex">
          <img
            width="100%"
            height="95"
            style={{ backgroundColor: "white" }}
            src={img}
            alt="some"
          />
        </div>
      </Paper>
    </Grid>
  );
};

export default memo(CryptoCard);
