import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { calculateGasMargin, getSigner } from "../utils";
import { useSelectedContract } from "./useContract";

export function useUnstakeCallback(tokenName) {
  const { chainId, library, account } = useWeb3React();
  const contract = useSelectedContract(tokenName);

  return useCallback(
    async (stakeNum) => {
      if (!chainId) {
        console.error("no chainId");
        return;
      }

      if (!contract) {
        console.error("contract is null");
        return;
      }

      if (!stakeNum) {
        console.error("stakeNum is null");
        return;
      }

      const signer = getSigner(library, account);

      const estimatedGas = await contract
        .connect(signer)
        .estimateGas.unstake(stakeNum.toString());

      // actual txn
      const response = await contract
        .connect(signer)
        .unstake(stakeNum.toString(), {
          gasLimit: calculateGasMargin(estimatedGas),
        });
      // console.log("response >>> ", response);
      return response;
    },
    [chainId, contract, library, account]
  );
}
