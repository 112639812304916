import { SupportedChainId } from "./chains";

// Staking contract addresses
export const ETH_STAKING_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0xF2eB21EbF921D14Da62906b6ccE46974B7a04AA4",
  [SupportedChainId.RINKEBY]: "0xc08Dbd8D2805cCBB33eccaA3880469AcCB90bd42",
};

export const DFCH_STAKING_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0xc7F075aeE33844b1F8818f9c8c127108CEb6CcD4",
  [SupportedChainId.RINKEBY]: "0x11e1aD6cf4fB8f83D90cbdeC9e36d671B8845da7",
};

export const SHIB_STAKING_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0x513C67Aa32Dc93ffC1850431FF8e9A405EAFE9f1",
  [SupportedChainId.RINKEBY]: "0x6D723902e5fdB8e87EF524e3C6cd20403c91163d",
};

// Token contract addresses
export const DFCH_TOKEN_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0xA92cDf011c895780733b2a6A6e15C4c1ed836148",
  [SupportedChainId.RINKEBY]: "0xb7505F6d527425af411CB889f3AEf2C3a9c7e857",
};

export const SHIB_TOKEN_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
  [SupportedChainId.RINKEBY]: "0xbB95713Aa0Ce17eF9556b23f3FF221d8C7D4b6A4",
};

// Multicall contract addresses
export const MULTICALL_CONTRACT_ADDRESSES = {
  [SupportedChainId.MAINNET]: "0x1F98415757620B543A52E61c46B32eB19261F984",
  [SupportedChainId.RINKEBY]: "0x1F98415757620B543A52E61c46B32eB19261F984",
};
