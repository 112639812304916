import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import WalletModal from "../WalletModal/WalletModal";
import { ReactComponent as Logo } from "../../assets/logo/LOGO.svg";
import { useStyles } from "./styles";

export default function Navbar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.customColor}>
        <Toolbar>
          <a rel="noreferrer" target="_blank" href="https://www.defi.ch">
            <Logo className={classes.logo} />
          </a>

          <span className={classes.mobile}>+ 44 (0) 123 456 789</span>

          <WalletModal />
        </Toolbar>
      </AppBar>
    </div>
  );
}
