import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserState } from "./slice";

// returns boolean value
export function useUpdatedUser() {
  return useSelector((state) => state.user.updated);
}

// toggles the modal open/close
export function useUpdateUserState() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(updateUserState()), [dispatch]);
}
