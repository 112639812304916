import { useWeb3React } from "@web3-react/core";
import { useEagerConnect, useInactiveListener } from "../../hooks";

const MessageWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "20rem",
    }}
  >
    {children}
  </div>
);

export default function Web3ReactManager({ children }) {
  const { active, error } = useWeb3React();

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    console.log("triedEager not connected!");
    return null;
  }

  if (!active && error) {
    console.log("not active and error!");
    return (
      <MessageWrapper>
        <h2
          style={{
            color: "#5E0200",
          }}
        >
          Oops! An unknown error occurred. Please refresh the page, or visit
          from another browser or device.
        </h2>
      </MessageWrapper>
    );
  }

  return children;
}
