import { configureStore } from "@reduxjs/toolkit";

import { applicationReducer } from "./application/slice";
import { multicallReducer } from "./multicall/slice";
import { logsReducer } from "./logs/slice";
import { userReducer } from "./user/slice";

const store = configureStore({
  reducer: {
    user: userReducer,
    application: applicationReducer,
    multicall: multicallReducer,
    logs: logsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
});

export default store;
