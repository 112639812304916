import { useWeb3React } from "@web3-react/core";
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Link as MuiLink,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import { ReactComponent as Cross } from "../../assets/images/Cross.svg";
import { ReactComponent as Error } from "../../assets/images/Error.svg";
import { ReactComponent as Arrow } from "../../assets/images/Arrow.svg";
import { getEtherscanLink } from "../../utils";
import CircularProgress from "./CircularProgress";

export function ConfirmationPendingContent({
  onDismiss = () => {},
  pendingText = null,
}) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid style={{ marginTop: 3, marginBottom: 15 }} item xs={11}></Grid>
        <Grid item xs={1}>
          <Cross onClick={onDismiss} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid style={{ marginTop: 80 }} xs={12}>
          <CircularProgress />
        </Grid>

        <Grid style={{ marginTop: 60, textAlign: "center" }} xs={12}>
          <span style={{ fontSize: "20px", fontWeight: 500 }}>
            Waiting For Confirmation
          </span>
        </Grid>

        <Grid style={{ marginTop: 10, textAlign: "center" }} xs={12}>
          <span style={{ fontSize: "15px", fontWeight: 500 }}>
            {pendingText}
          </span>
        </Grid>
        <Grid
          style={{ marginTop: 10, textAlign: "center", marginBottom: 10 }}
          xs={12}
        >
          <span
            style={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgb(86, 90, 105)",
            }}
          >
            Confirm this transaction in your wallet
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export function TransactionSubmittedContent({
  onDismiss = () => {},
  chainId,
  hash,
}) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid style={{ marginTop: 3, marginBottom: 15 }} item xs={11}></Grid>
        <Grid item xs={1}>
          <Cross onClick={onDismiss} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid style={{ marginTop: 80, textAlign: "center" }} xs={12}>
          <Arrow />
        </Grid>

        <Grid style={{ marginTop: 60, textAlign: "center" }} xs={12}>
          <span style={{ fontSize: "20px", fontWeight: 500 }}>
            Transaction Submitted
          </span>
        </Grid>

        <Grid style={{ marginTop: 10, textAlign: "center" }} xs={12}>
          <MuiLink
            href={getEtherscanLink(chainId, hash, "transaction")}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.anchor}
          >
            View on Explorer
          </MuiLink>
        </Grid>

        <Grid
          style={{ marginTop: 10, textAlign: "center", marginBottom: 10 }}
          xs={12}
        >
          <span>
            <Button
              style={{
                backgroundColor: "#5E0200",
                color: "white",
                width: "100%",
                borderRadius: 50,
                fontSize: 20,
              }}
              variant="contained"
              onClick={onDismiss}
            >
              Close
            </Button>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export function TransactionErrorContent({
  onDismiss = () => {},
  message = null,
}) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid
          style={{ marginTop: 3, marginBottom: 15, fontSize: "20px" }}
          item
          xs={11}
        >
          Error
        </Grid>
        <Grid item xs={1}>
          <Cross onClick={onDismiss} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid style={{ marginTop: 20, textAlign: "center" }} xs={12}>
          <Error />
        </Grid>

        <Grid style={{ marginTop: 20, textAlign: "center" }} xs={12}>
          <span style={{ fontSize: "20px", color: "red" }}>
            {message ? message : "Transaction Rejected."}
          </span>
        </Grid>

        <Grid
          style={{ marginTop: 30, textAlign: "center", marginBottom: 30 }}
          xs={12}
        >
          <span>
            <Button
              style={{
                backgroundColor: "#5E0200",
                color: "white",
                width: "100%",
                borderRadius: 50,
                fontSize: 20,
              }}
              variant="contained"
              onClick={onDismiss}
            >
              Dismiss
            </Button>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

// interface ConfirmationModalProps {
//   isOpen: boolean
//   onDismiss: () => void
//   hash: string | undefined
//   attemptingTxn: boolean
//   pendingText: ReactNode
// }
export default function TransactionConfirmationModal({
  isOpen,
  onDismiss = () => {},
  attemptingTxn,
  hash,
  pendingText = null,
  errorText = null,
}) {
  const { chainId } = useWeb3React();

  if (!chainId) return null;

  // confirmation screen
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={onDismiss}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        {attemptingTxn ? (
          <ConfirmationPendingContent
            onDismiss={onDismiss}
            pendingText={pendingText}
          />
        ) : hash ? (
          <TransactionSubmittedContent
            chainId={chainId}
            hash={hash}
            onDismiss={onDismiss}
          />
        ) : (
          <TransactionErrorContent onDismiss={onDismiss} message={errorText} />
        )}
      </Fade>
    </Modal>
  );
}
