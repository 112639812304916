import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card_paper: {
    padding: 4,
    cursor: "pointer",
    backgroundColor: "#5E0200",
  },
}));

export { useStyles };
