import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { isMobile } from "react-device-detect";

import injectedLogo from "../assets/wallets/injected.png";
import metamaskLogo from "../assets/wallets/metamask.png";
import walletconnectLogo from "../assets/wallets/walletconnect.png";
import coinbaseLogo from "../assets/wallets/coinbase.png";
import { injected, walletconnect, walletlink } from "../connectors";

export const injectedConnectorList = [
  {
    name: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
    icon: metamaskLogo,
    text: "Easy-to-use browser extension",
  },
  {
    name: "Injected",
    connector: injected,
    connectorType: InjectedConnector,
    icon: injectedLogo,
    text: "Injected web3 provider.",
  },
];

export const desktopWalletList = [
  {
    name: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
    icon: metamaskLogo,
    text: "Easy-to-use browser extension",
  },
  {
    name: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
    icon: walletconnectLogo,
    text: "Connect to Trust Wallet, Rainbow Wallet and more...",
  },
  {
    name: "Coinbase",
    connector: walletlink,
    connectorType: WalletLinkConnector,
    icon: coinbaseLogo,
    text: "Use Coinbase Wallet app on mobile device",
  },
];

export const mobileWalletList = [
  {
    name: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
    icon: walletconnectLogo,
    text: "Connect to Trust Wallet, Rainbow Wallet and more...",
  },
  {
    name: "Open in Coinbase Wallet",
    icon: coinbaseLogo,
    text: "Open in Coinbase Wallet app.",
    href: "https://go.cb-w.com/mtUDhEZPy1",
  },
];

export const installMetamask = {
  name: "Install Metamask",
  icon: metamaskLogo,
  link: "https://metamask.io/",
};

const isMetamask = window?.ethereum?.isMetaMask;

export const walletList = isMobile
  ? window?.ethereum
    ? [isMetamask ? injectedConnectorList[0] : injectedConnectorList[1]]
    : mobileWalletList
  : !(window.web3 || window.ethereum)
  ? [installMetamask, ...desktopWalletList]
  : desktopWalletList;
