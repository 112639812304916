import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    flexGrow: 1,
  },

  customColor: {
    backgroundColor: "#5E0200",
  },

  mobile: {
    visibility: "hidden",
    flexGrow: 1,
    top: "0px",
    overflow: "visible",
    whiteSpace: "nowrap",
    textAlign: "left",
    // fontFamily: "K2D",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "rgba(246, 39, 23, 1)",
    letterSpacing: "1.2px",

    [theme.breakpoints.down("lg")]: {
      left: "31px",
      marginLeft: "13px",
      width: "193px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      left: "20px",
      marginLeft: "9px",
      width: "160px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "15px",
      marginLeft: "5px",
      width: "140px",
      fontSize: "9px",
    },
  },

  logo: {
    [theme.breakpoints.down("xl")]: {
      marginLeft: 45,
      width: 150,
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 45,
      width: 150,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 35,
      width: 120,
    },

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: 90,
    },
  },
}));

export { useStyles };
