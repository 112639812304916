import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Provider } from "react-redux";
import store from "./redux";
import ApplicationUpdater from "./redux/application/updater";
import MulticallUpdater from "./redux/multicall/updater";
import LogsUpdater from "./redux/logs/updater";
import App from "./App";
import "./index.css";

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

export function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function Updater() {
  return (
    <>
      <ApplicationUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  );
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <>
          <Updater />
          <App />
        </>
      </Web3ReactProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
