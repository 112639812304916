import { useMemo } from "react";
import { Interface } from "@ethersproject/abi";
import ETHStakingABI from "../constants/abis/eth-staking.json";
import TokenStakingABI from "../constants/abis/token-staking.json";
import { useSelectedContract } from "../hooks/useContract";
import { useLogs } from "../redux/logs/hooks";
import { useWeb3React } from "@web3-react/core";
import { SupportedTokens } from "../constants/tokens";

// Both have same stake and unstake logs
const ETHStakingInterface = new Interface(ETHStakingABI);
const TokenStakingInterface = new Interface(TokenStakingABI);

export function useFormattedStakedLogs(tokenName = SupportedTokens.ETH) {
  const { account } = useWeb3React();

  // create filters for Staked events
  const contract = useSelectedContract(tokenName);

  const filter = useMemo(
    () => contract?.filters?.Staked(account),
    [account, contract]
  );
  const useLogsResult = useLogs(filter);

  return useMemo(() => {
    return useLogsResult?.logs
      ?.map((log) => {
        // log: { topics: string[]; data: string; }
        let parsed;
        if (tokenName === SupportedTokens.ETH) {
          parsed = ETHStakingInterface.parseLog(log).args;
        } else {
          parsed = TokenStakingInterface.parseLog(log).args;
        }
        return parsed;
      })
      ?.map((parsed) => {
        const { user, amount, stakeNum } = parsed;
        return { user, amount, stakeNum: Number(stakeNum) };
      });
  }, [tokenName, useLogsResult]);
}

export function useFormattedUnstakedLogs(tokenName = SupportedTokens.ETH) {
  const { account } = useWeb3React();

  // create filters for Unstaked events
  const contract = useSelectedContract(tokenName);

  const filter = useMemo(
    () => contract?.filters?.Unstaked(account),
    [account, contract]
  );
  const useLogsResult = useLogs(filter);

  return useMemo(() => {
    return useLogsResult?.logs
      ?.map((log) => {
        // log: { topics: string[]; data: string; }
        let parsed;
        if (tokenName === SupportedTokens.ETH) {
          parsed = ETHStakingInterface.parseLog(log).args;
        } else {
          parsed = TokenStakingInterface.parseLog(log).args;
        }
        return parsed;
      })
      ?.map((parsed) => {
        const { user, amount, reward, stakeNum } = parsed;
        return { user, amount, reward, stakeNum: Number(stakeNum) };
      });
  }, [tokenName, useLogsResult]);
}

export function useFormattedUserStakeNumsLogs(tokenName = SupportedTokens.ETH) {
  const stakeLogs = useFormattedStakedLogs(tokenName);
  const unstakeLogs = useFormattedUnstakedLogs(tokenName);

  return useMemo(
    () =>
      stakeLogs
        ?.map(({ stakeNum }) => stakeNum)
        ?.filter(
          (stakeNum) =>
            !unstakeLogs?.map(({ stakeNum }) => stakeNum)?.includes(stakeNum)
        ),
    [stakeLogs, unstakeLogs]
  );
}
