import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  FormLabel,
  TextField,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Grid,
} from "@material-ui/core";
import Withdraw from "../Withdraw/Withdraw";
import { formatValue, fromWei } from "../../utils";
import { useStakeCallback } from "../../hooks/useStakeCallback";
import { useFormattedUserStakeNumsLogs } from "../../data/events";
import { useSelectedTokenBalance } from "../../data";
import {
  ApprovalState,
  useApproveCallback,
} from "../../hooks/useApproveCallback";
import {
  getTokenAddress,
  getStakingAddress,
  SupportedTokens,
} from "../../constants/tokens";
import { useWeb3React } from "@web3-react/core";
import TransactionConfirmationModal from "../TransactionConfirmationModal/TransactionConfirmationModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TextFieldWrapper({ isError, label, onClick, onChange, value }) {
  const classes = useStyles();

  return (
    <TextField
      error={isError}
      size="small"
      id="outlined-number"
      label={label}
      InputProps={{
        endAdornment: (
          <Button className={classes.btn} onClick={onClick}>
            Set Max
          </Button>
        ),
        inputProps: {
          min: 0,
          step: "any",
        },
      }}
      type="number"
      variant="outlined"
      required
      onChange={onChange}
      value={value}
      defaultValue={0}
      aria-describedby="component-error-text"
    />
  );
}

export default function FullWidthTabs({ tokenName }) {
  const classes = useStyles();
  const theme = useTheme();

  const [error, setButtonError] = useState(false);
  const [value, setValue] = useState(0);

  const [amountToStake, setAmountToStake] = useState(0);

  // single state for modal and loading
  const [
    { showConfirm, attemptingTxn, txHash, errorMessage, pendingMessage },
    setModalState,
  ] = useState({
    showConfirm: false,
    attemptingTxn: false,
    txHash: undefined,
    errorMessage: undefined,
    pendingMessage: undefined,
  });

  // web3 react
  const { chainId } = useWeb3React();

  // for withdraw
  const userStakeNums = useFormattedUserStakeNumsLogs(tokenName);

  // selectedTokenBalance
  const selectedTokenBalance = useSelectedTokenBalance(tokenName);

  // stake callback
  const stakeCallback = useStakeCallback(tokenName);

  const [approvalState, approveCallback] = useApproveCallback(
    getTokenAddress(chainId, tokenName),
    amountToStake,
    getStakingAddress(chainId, tokenName)
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!amountToStake) return;

      if (Number(amountToStake) <= fromWei({ num: selectedTokenBalance })) {
        setModalState({
          showConfirm: true,
          attemptingTxn: true,
          txHash: undefined,
          errorMessage: undefined,
          pendingMessage: undefined,
        });

        try {
          let response, resWait;
          if (tokenName !== SupportedTokens.ETH) {
            if (approvalState === ApprovalState.NOT_APPROVED) {
              setModalState((prev) => ({
                ...prev,
                pendingMessage: <>Approval for {tokenName}</>,
              }));
              const approveRes = await approveCallback();
              // console.log("approveRes >>> ", approveRes);
              resWait = await approveRes?.wait();
            }
          }

          if (
            tokenName === SupportedTokens.ETH ||
            resWait?.status === 1 ||
            approvalState === ApprovalState.APPROVED
          ) {
            setModalState((prev) => ({
              ...prev,
              pendingMessage: (
                <>
                  Staking {amountToStake} {tokenName}
                </>
              ),
            }));
            response = await stakeCallback(amountToStake);

            setModalState({
              showConfirm: true,
              attemptingTxn: true,
              txHash: response.hash,
              errorMessage: undefined,
              pendingMessage: undefined,
            });

            resWait = await response.wait();
            if (resWait.status === 1) {
              setModalState({
                showConfirm: true,
                attemptingTxn: false,
                txHash: response.hash,
                errorMessage: undefined,
                pendingMessage: undefined,
              });
            } else {
              setModalState((prev) => ({
                ...prev,
                showConfirm: false,
              }));
            }
          }
        } catch (error) {
          setModalState((prev) => ({
            ...prev,
            attemptingTxn: false,
            // errorMessage: error.message,
            errorMessage: undefined,
          }));

          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error);
          }
        }
      } else {
        setButtonError(true);
      }
    },
    [
      tokenName,
      approvalState,
      approveCallback,
      amountToStake,
      selectedTokenBalance,
      stakeCallback,
    ]
  );

  const handleConfirmDismiss = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      showConfirm: false,
      attemptingTxn,
      txHash,
    }));
  }, [attemptingTxn, txHash]);

  return (
    <>
      <div className={classes.root}>
        <AppBar
          style={{
            borderRadius: 5,
          }}
          position="static"
          color="white"
          elevation={1}
        >
          <Tabs
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
            indicatorColor="#5E0200"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              className={classes.tab2}
              style={{
                borderRight: "0.5px solid #D0D0D0",
              }}
              label="Deposit"
              {...a11yProps(0)}
            />

            <Tab className={classes.tab2} label="Withdraw" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={(index) => setValue(index)}
          style={{ border: "1px solid #D0D0D0", borderRadius: "0 0 5px 5px" }}
        >
          <TabPanel
            className={classes.parentTab}
            value={value}
            index={0}
            dir={theme.direction}
          >
            <span className={classes.tab}>
              Receive Savings on your {tokenName}. Deposit or withdraw at any
              time
            </span>
            <br />
            <br />
            <br />

            <span className={classes.deposit}>Deposit AMOUNT</span>
            <br />
            <form
              style={{ textAlign: "center" }}
              className={classes.input}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid className={classes.gridstyle} xs={9} lg={6} item>
                  <FormLabel>
                    {tokenName} Balance:{" "}
                    <span>{formatValue({ num: selectedTokenBalance })}</span>{" "}
                    {tokenName}
                  </FormLabel>
                </Grid>
                <Grid xs={12} item>
                  <TextFieldWrapper
                    isError={error}
                    label={error ? "Amount Exceeds Balance" : "Enter Amount"}
                    onClick={() =>
                      setAmountToStake(
                        formatValue({ num: selectedTokenBalance })
                      )
                    }
                    onChange={(e) => setAmountToStake(e.target.value)}
                    value={amountToStake}
                  />
                </Grid>
              </Grid>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button type="submit" className={classes.btn2}>
                  Deposit
                </Button>
              </div>
            </form>
          </TabPanel>

          <TabPanel
            style={{ overflowY: "scroll", height: "310px" }}
            className={classes.parentTab2}
            value={value}
            index={1}
            dir={theme.direction}
          >
            {userStakeNums === undefined || userStakeNums?.length === 0 ? (
              <p
                style={{
                  textAlign: "center",
                }}
              >
                No stakes available to withdraw
              </p>
            ) : (
              userStakeNums.map((stakeNum, i) => {
                return (
                  <Withdraw key={i} stakeNum={stakeNum} tokenName={tokenName} />
                );
              })
            )}
          </TabPanel>
        </SwipeableViews>
      </div>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleConfirmDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText={pendingMessage}
        errorText={errorMessage}
      />
    </>
  );
}
