import React, { useEffect, useState } from "react";
import { Modal as MuiModal, Backdrop, Fade, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Option from "./Option/Option";
import { useStyles } from "./styles";

import { ReactComponent as Cross } from "../../assets/images/Cross.svg";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { shortenAddress } from "../../utils";
import ProgressAnim from "../Progress/Progress";
import {
  useWalletModalOpen,
  useWalletModalToggle,
} from "../../redux/application/hooks";
import usePrevious from "../../hooks/usePrevious";
import AccountDetails from "./AccountDetails/AccountDetails";
import PendingView from "./PendingView/PendingView";
import { useUpdateUserState } from "../../redux/user/hooks";
import { walletList } from "../../constants/wallet";
import { injected } from "../../connectors";

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

export default function WalletConnect() {
  const classes = useStyles();

  // console.log("walletList", walletList);
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState();
  const [pendingError, setPendingError] = useState();

  const walletModalOpen = useWalletModalOpen();
  const toggleWalletModal = useWalletModalToggle();

  const updateUserState = useUpdateUserState();

  const web3context = useWeb3React();
  const { activate, deactivate, connector, account, active, error } =
    web3context;
  // console.log("web3context", web3context);

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (connector) => {
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect,
    // manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((e) => {
        // const err = getErrorMessage(e);
        console.error("ERROR activateWallet -> ", e);
        if (error instanceof UnsupportedChainIdError) {
          // a little janky...can't use setError because the connector isn't set
          activate(connector);
        } else {
          setPendingError(true);
        }
      });

    updateUserState();
  };

  const tryDeactivation = () => {
    deactivate();
    toggleWalletModal();
  };

  function getOptions() {
    return walletList.map((option, i) => {
      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          return null; //don't want to return metamask twice
        }
      }

      // return rest of options
      return (
        <Option
          key={i}
          name={option.name}
          icon={option.icon}
          text={option.text}
          link={option.link}
          connector={option.connector}
          active={option.connector && option.connector === connector}
          onClick={() => {
            option.connector === connector
              ? setWalletView(WALLET_VIEWS.ACCOUNT)
              : !option.href && tryActivation(option.connector);
          }}
        />
      );
    });
  }

  function getModalContent() {
    if (error) {
      return (
        <Grid container>
          <Grid style={{ marginBottom: 20, marginTop: 5 }} item xs={11}>
            <span
              style={{
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {error instanceof UnsupportedChainIdError
                ? "Wrong Network"
                : "Error connecting"}
            </span>
          </Grid>
          <Grid item xs={1}>
            <Cross
              onClick={toggleWalletModal}
              style={{ cursor: "pointer", marginTop: 2 }}
            />
          </Grid>
          <Grid xs={12}>
            <div className={classes.paper3}>
              <Grid container>
                <Grid style={{ marginTop: 2 }} xs={1}>
                  <ProgressAnim />
                </Grid>
                <Grid xs={7}>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      marginLeft: 10,
                    }}
                  >
                    {error instanceof UnsupportedChainIdError ? (
                      <h5>
                        Please connect to the appropriate Ethereum network.
                      </h5>
                    ) : (
                      "Error connecting. Try refreshing the page."
                    )}
                  </p>
                </Grid>
                <Grid xs={4}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      );
    }

    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
          tryDeactivation={tryDeactivation}
        />
      );
    }

    return (
      <Grid container>
        <Grid style={{ marginBottom: 20, marginTop: 5 }} item xs={11}>
          {walletView !== WALLET_VIEWS.ACCOUNT ? (
            <strong
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
              style={{ cursor: "pointer" }}
            >
              Back
            </strong>
          ) : (
            <span
              style={{
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Connect to a wallet
            </span>
          )}
        </Grid>

        <Grid item xs={1}>
          <Cross
            onClick={toggleWalletModal}
            style={{ cursor: "pointer", marginTop: 2 }}
          />
        </Grid>

        {walletView === WALLET_VIEWS.PENDING ? (
          <PendingView
            connector={pendingWallet}
            error={pendingError}
            setPendingError={setPendingError}
            tryActivation={tryActivation}
          />
        ) : (
          getOptions()
        )}
      </Grid>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        className={classes.btn}
        onClick={toggleWalletModal}
      >
        {active && account ? shortenAddress(account) : "CONNECT WALLET"}
      </Button>

      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={walletModalOpen}
        onClose={toggleWalletModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={walletModalOpen}>
          <div className={classes.paper}>{getModalContent()}</div>
        </Fade>
      </MuiModal>
    </>
  );
}
