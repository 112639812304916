import { memo } from "react";
import {
  useUserTotalBalance,
  useUserTotalReward,
  useStakingAPY,
  useUSDPerTokenPrice,
} from "../../data";
import { fromWei, noEndZeros, toFix } from "../../utils";
import { Divider, Grid, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TOKEN_INFO } from "../../constants/tokens";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  balanceStyle: {
    overflow: "visible",
    marginLeft: 30,
    whiteSpace: "nowrap",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: isMobile ? "16px" : "24px",
    color: "rgba(25,20,34,1)",
  },
  title: {
    fontSize: 24,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default memo(function Balance({ tokenName }) {
  const classes = useStyles();

  const apy = useStakingAPY(tokenName); // BN
  const userTotalBal = useUserTotalBalance(tokenName); // number
  const userTotalReward = useUserTotalReward(tokenName); // number
  const usdPerToken = useUSDPerTokenPrice(tokenName); // number

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid style={{ marginTop: 16 }} item xs={4}>
          <Typography className={classes.balanceStyle}>
            <span>BALANCE</span>
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid style={{ marginTop: 7 }} item xs={6} lg={6}>
          <Typography
            style={{ float: "right" }}
            className={classes.balanceStyle}
          >
            <span>
              {!Boolean(userTotalBal) || !Boolean(userTotalReward)
                ? "0"
                : noEndZeros(
                    toFix({
                      num: Number(userTotalBal) + Number(userTotalReward),
                      precision: 8,
                    })
                  )}
            </span>{" "}
            <span style={{ fontSize: "12px" }}>{tokenName}</span>
            <p style={{ fontSize: "10px", marginTop: -2 }}>
              <span>
                {noEndZeros(
                  toFix({
                    num: usdPerToken * (userTotalBal + userTotalReward),
                  })
                )}{" "}
                USD
              </span>
            </p>
          </Typography>
        </Grid>
        <Grid item xs={1} lg={0}></Grid>
      </Grid>

      <Divider />
      <Typography className={classes.balanceStyle}>
        <p style={{ fontSize: "14px" }}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <span>{tokenName} Savings rate (APY)</span>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid style={{ textAlign: "center" }} item xs={3}>
              <span style={{ float: "right" }}>
                {apy ? fromWei({ num: apy }) : TOKEN_INFO[tokenName].apy}%
              </span>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </p>
      </Typography>
    </Card>
  );
});
