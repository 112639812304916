import { useCallback, useState } from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { formatValue } from "../../utils";
import { useUnstakeCallback } from "../../hooks/useUnstakeCallback";
import { useUserStakedReward, useUserStakedBalance } from "../../data";
import TransactionConfirmationModal from "../TransactionConfirmationModal/TransactionConfirmationModal";

export default function Withdraw({ stakeNum, tokenName }) {
  const userBal = useUserStakedBalance({ stakeNum, tokenName });
  const userRew = useUserStakedReward({ stakeNum, tokenName });

  // single state for modal and loading
  const [
    { showConfirm, attemptingTxn, txHash, errorMessage, pendingMessage },
    setModalState,
  ] = useState({
    showConfirm: false,
    attemptingTxn: false,
    txHash: undefined,
    errorMessage: undefined,
    pendingMessage: undefined,
  });

  // unstake callback
  const unstakeCallback = useUnstakeCallback(tokenName);

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();

      if (formatValue({ num: userBal }) > 0 && stakeNum) {
        setModalState({
          showConfirm: true,
          attemptingTxn: true,
          txHash: undefined,
          errorMessage: undefined,
          pendingMessage: undefined,
        });

        try {
          setModalState((prev) => ({
            ...prev,
            pendingMessage: (
              <>
                Unstaking{" "}
                {(
                  formatValue({ num: userRew }) + formatValue({ num: userRew })
                ).toFixed(8)}{" "}
                {tokenName}
              </>
            ),
          }));
          const response = await unstakeCallback(stakeNum);
          // console.log("response >>> ", response);
          setModalState({
            showConfirm: true,
            attemptingTxn: true,
            txHash: response.hash,
            errorMessage: undefined,
            pendingMessage: undefined,
          });
          const resWait = await response.wait();
          if (resWait.status === 1) {
            setModalState({
              showConfirm: true,
              attemptingTxn: false,
              txHash: response.hash,
              errorMessage: undefined,
              pendingMessage: undefined,
            });
          } else {
            setModalState((prev) => ({
              ...prev,
              showConfirm: false,
            }));
          }
        } catch (error) {
          setModalState((prev) => ({
            ...prev,
            attemptingTxn: false,
            // errorMessage: error.message,
            errorMessage: undefined,
          }));

          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error);
          }
        }
      } else {
        console.log("null");
        return;
      }
    },
    [userBal, stakeNum, unstakeCallback, tokenName, userRew]
  );

  const handleConfirmDismiss = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      showConfirm: false,
      attemptingTxn,
      txHash,
    }));
  }, [attemptingTxn, txHash]);

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Paper variant="outlined">
          <Grid style={{ padding: 5 }} container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <span>
                    {formatValue({ num: userBal })} {tokenName}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span>amount</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <span>
                    {formatValue({ num: userRew, precision: 8 })} {tokenName}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span>reward</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid align="center" style={{ marginTop: 5 }} item xs={4}>
              <Button
                style={{ width: 90 }}
                variant="outlined"
                onClick={(e) => handleClick(e)}
              >
                Unstake
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleConfirmDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText={pendingMessage}
        errorText={errorMessage}
      />
    </>
  );
}
