import Grid from "@material-ui/core/Grid";
import { ReactComponent as Logo } from "../../assets/logo/LOGO.svg";
import { useStyles } from "./styles";

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.backgroundImg}>
      <Grid align="center" container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid className={classes.grid} container spacing={3}>
            <Grid align="center" item xs={12} lg={2}>
              {/* <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.listTypography}>
                    Contact
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul className={classes.list2}>
                    <li className={classes.listItem}>
                      Decentralized Finance AG
                    </li>
                    <li className={classes.listItem}>Email: info@defi.ch</li>

                    <li
                      style={{ lineHeight: "150%", marginTop: 5 }}
                      className={classes.listItem}
                    >
                      Address: Bahnhofstrasse 16, 6300 Zug, Switzerland
                    </li>
                  </ul>
                </Grid>
              </Grid>*/}
            </Grid>

            <Grid align="center" item xs={12} lg={8}>
              <Grid container>
                <Grid item xs={12}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.defi.ch"
                  >
                    <Logo className={classes.logo} />
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item xs={12}>
            <hr
              style={{ borderColor: "rgba(246,39,23,1)" }}
              className={classes.footerBottom}
            />
          </Grid>
          <Grid className={classes.fbottom2} item xs={12}>
            <span>
              {" "}
              All Right Reserved by{" "}
              <a
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
                href="https://www.defi.ch"
              >
                <span style={{ color: "rgba(246,39,23,1)" }}>defi.ch</span>{" "}
              </a>
              ©2021{" "}
            </span>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
  );
};

export default Footer;
