import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SupportedTokens } from "../constants/tokens";
import { useSelectedContract } from "../hooks/useContract";
import { useSingleCallResult } from "../redux/multicall/hooks";
import { formatValue, toBN } from "../utils";
import { useFormattedUserStakeNumsLogs } from "./events";
import axios from "axios";
import { useETHBalance } from "../redux/wallet/hooks";
import { getTokenAddress } from "../constants/tokens";
import { useTokenBalance } from "./token";

// shib -> shiba-inu

export function useUSDPerTokenPrice(tokenName = SupportedTokens.ETH) {
  const [state, setState] = useState(0);

  const token = useMemo(() => {
    if (tokenName === SupportedTokens.ETH) return "ethereum";
    if (tokenName === SupportedTokens.SHIB) return "shiba-inu";
    if (tokenName === SupportedTokens.DFCH) return "defi-ch";

    return "ethereum";
  }, [tokenName]);

  const usdPerTokenCallback = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=usd`
        )
      ).data;
      // console.log("response >>> ", response);
      setState(Number(response[token].usd));
    } catch (error) {
      console.log("useUSDPerTokenPrice: ", error);
    }
  }, [token]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      usdPerTokenCallback();
    }

    return () => {
      mounted = false;
    };
  }, [usdPerTokenCallback]);

  return useMemo(() => state, [state]);
}

export function useStakingAPY(tokenName = SupportedTokens.ETH) {
  const { chainId } = useWeb3React();
  const contract = useSelectedContract(tokenName);

  const apyCall = useSingleCallResult(contract, "apy", undefined);
  // console.log("apyCall >>> ", apyCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (apyCall.loading) return null;
    if (apyCall.result) {
      return apyCall.result?.[0];
    }
    return undefined;
  }, [chainId, apyCall]);
}

export function useUserStakedBalance({
  stakeNum,
  tokenName = SupportedTokens.ETH,
}) {
  const { chainId, account } = useWeb3React();
  const contract = useSelectedContract(tokenName);

  const inputs = useMemo(() => [account, stakeNum], [account, stakeNum]);
  const balanceOfCall = useSingleCallResult(contract, "balanceOf", inputs);
  // console.log("balanceOfCall >>> ", balanceOfCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (balanceOfCall.loading) return null;
    if (balanceOfCall.result) {
      return balanceOfCall.result?.[0];
    }
    return undefined;
  }, [chainId, balanceOfCall]);
}

export function useUserTotalBalance(tokenName = SupportedTokens.ETH) {
  const { account } = useWeb3React();
  const userStakeNums = useFormattedUserStakeNumsLogs(tokenName);
  const contract = useSelectedContract(tokenName);

  const [userTotalBalance, setUserTotalBalance] = useState(toBN("0"));

  const balanceOfCallback = useCallback(async () => {
    let bal = toBN("0");

    for (let i = 0; i < userStakeNums.length; i++) {
      const ub = await contract.balanceOf(account, userStakeNums[i]);
      bal = ub ? bal.add(toBN(ub)) : toBN("0");
    }

    setUserTotalBalance(formatValue({ num: bal, precision: 8 }));
  }, [userStakeNums, account, contract]);

  useEffect(() => {
    let mounted = true;
    if (!contract || !userStakeNums) return;

    mounted && balanceOfCallback();

    return () => {
      mounted = false;
    };
  }, [contract, userStakeNums, balanceOfCallback]);

  return useMemo(() => userTotalBalance, [userTotalBalance]);
}

export function useUserStakedReward({
  stakeNum,
  tokenName = SupportedTokens.ETH,
}) {
  const { chainId, account } = useWeb3React();
  const contract = useSelectedContract(tokenName);

  const inputs = useMemo(() => [account, stakeNum], [account, stakeNum]);
  const calculateRewardCall = useSingleCallResult(
    contract,
    "calculateReward",
    inputs
  );
  // console.log("calculateRewardCall >>> ", calculateRewardCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (calculateRewardCall.loading) return null;
    if (calculateRewardCall.result) {
      return calculateRewardCall.result?.[0];
    }
    return undefined;
  }, [chainId, calculateRewardCall]);
}

export function useUserTotalReward(tokenName = SupportedTokens.ETH) {
  const { account } = useWeb3React();
  const userStakeNums = useFormattedUserStakeNumsLogs(tokenName);
  const contract = useSelectedContract(tokenName);

  const [userTotalReward, setUserTotalReward] = useState(toBN("0"));

  const calculateRewardCallback = useCallback(async () => {
    let reward = toBN("0");

    for (let i = 0; i < userStakeNums.length; i++) {
      const ub = await contract.calculateReward(account, userStakeNums[i]);
      reward = ub ? reward.add(toBN(ub)) : toBN("0");
    }

    setUserTotalReward(formatValue({ num: reward, precision: 8 }));
  }, [userStakeNums, account, contract]);

  useEffect(() => {
    let mounted = true;
    if (!contract || !userStakeNums) return;

    mounted && calculateRewardCallback();

    return () => {
      mounted = false;
    };
  }, [contract, userStakeNums, calculateRewardCallback]);

  return useMemo(() => userTotalReward, [userTotalReward]);
}

export function useSelectedTokenBalance(tokenName = SupportedTokens.ETH) {
  const ethBalance = useETHBalance(); // BN
  const { chainId, account } = useWeb3React();
  const address = getTokenAddress(chainId, tokenName);
  const tokenBalance = useTokenBalance(address, account);

  return useMemo(() => {
    if (tokenName === SupportedTokens.ETH) return ethBalance;

    return tokenBalance;
  }, [tokenName, ethBalance, tokenBalance]);
}
