import React from "react";
import { Grid, Button } from "@material-ui/core";
import ProgressAnim from "../../Progress/Progress";
import { useStyles } from "./styles";
import { injected, walletconnect } from "../../../connectors";
import { walletList } from "../../../constants/wallet";

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}) {
  const classes = useStyles();
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <>
      <Grid xs={12}>
        {!error ? (
          <div className={classes.paper3}>
            <Grid container>
              <Grid style={{ marginTop: 2 }} xs={1}>
                <ProgressAnim />
              </Grid>
              <Grid xs={7}>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    marginLeft: 10,
                  }}
                >
                  {connector === walletconnect
                    ? "Scan QR code with a compatible wallet..."
                    : "Initializing..."}
                </p>
              </Grid>
              <Grid xs={4}></Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ border: "1px solid red" }} className={classes.paper3}>
            <Grid container>
              <Grid style={{ marginTop: 5, color: "red" }} xs={5}>
                Error Connecting
              </Grid>
              <Grid xs={7}>
                <Button
                  elevation={0}
                  size="small"
                  className={classes.tryagain}
                  variant="contained"
                  onClick={() => {
                    setPendingError(false);
                    tryActivation(connector);
                  }}
                >
                  Try Again
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>

      {walletList.map((option, i) => {
        if (option.connector === connector) {
          // console.log("option.connector >>> ", option.connector);
          // console.log("connector >>> ", connector);

          // overwrite injected when needed
          if (option.connector === injected) {
            if (isMetamask && option.name !== "Metamask") {
              // console.log("1");
              return null;
            }
            if (!isMetamask && option.name === "Metamask") {
              // console.log("2");
              return null;
            }
          }

          return (
            <Grid xs={12} key={i}>
              <div className={classes.paper2}>
                <Grid container>
                  <Grid xs={11}>
                    <Grid container>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontSize: 18,
                            margin: 0,
                            paddingTop: 1,
                          }}
                        >
                          {option.name}
                        </p>
                      </Grid>
                      <Grid style={{ marginTop: 8 }} item xs={12}>
                        <span style={{ fontSize: "12px" }}>{option.text}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={1}>
                    <img
                      style={{
                        marginTop: "50%",
                        float: "right",
                        marginBottom: 5,
                      }}
                      width="25px"
                      src={option.icon}
                      alt={option.alt}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        }
        return null;
      })}
    </>
  );
}
