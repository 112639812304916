import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  paper2: {
    cursor: "pointer",
    backgroundColor: "rgb(237, 238, 242)",
    padding: theme.spacing(2, 2, 1.7),
    borderRadius: theme.spacing(1.5),
    marginBottom: 12,
    border: "1px solid rgb(206, 208, 217)",

    "&:hover": {
      border: "1px solid red",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },
}));

export { useStyles };
