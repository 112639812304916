import { useWeb3React } from "@web3-react/core";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlockNumber } from "../application/hooks";
import { addListener, removeListener } from "./slice";
import { filterToKey } from "./utils";

export const LogsState = {
  // The filter is invalid
  INVALID: "INVALID",
  // The logs are being loaded
  LOADING: "LOADING",
  // Logs are from a previous block number
  SYNCING: "SYNCING",
  // Tried to fetch logs but received an error
  ERROR: "ERROR",
  // Logs have been fetched as of the latest block number
  SYNCED: "SYNCED",
};

// export interface UseLogsResult {
//   logs: Log[] | undefined
//   state: LogsState
// }

/**
 * Returns the logs for the given filter as of the latest block, re-fetching from the library every block.
 * @param filter (EventFilter | undefined) The logs filter, without `blockHash`, `fromBlock` or `toBlock` defined.
 * The filter parameter should _always_ be memoized, or else will trigger constant refetching
 * returns UseLogsResult
 */
export function useLogs(filter) {
  const { chainId } = useWeb3React();
  const blockNumber = useBlockNumber();

  const logs = useSelector((state) => state.logs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!filter || !chainId) return;

    dispatch(addListener({ chainId, filter }));
    return () => {
      dispatch(removeListener({ chainId, filter }));
    };
  }, [chainId, dispatch, filter]);

  const filterKey = useMemo(
    () => (filter ? filterToKey(filter) : undefined),
    [filter]
  );

  return useMemo(() => {
    if (!chainId || !filterKey || !blockNumber)
      return {
        logs: undefined,
        state: LogsState.INVALID,
      };

    const state = logs[chainId]?.[filterKey];
    const result = state?.results;
    if (!result) {
      return {
        state: LogsState.LOADING,
        logs: undefined,
      };
    }

    if (result.error) {
      return {
        state: LogsState.ERROR,
        logs: undefined,
      };
    }

    return {
      state:
        result.blockNumber >= blockNumber
          ? LogsState.SYNCED
          : LogsState.SYNCING,
      logs: result.logs,
    };
  }, [blockNumber, chainId, filterKey, logs]);
}
