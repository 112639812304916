import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useRef } from "react";
import Jazzicon from "@metamask/jazzicon";

export default function IdentIcon() {
  const ref = useRef();

  const { account } = useWeb3React();

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(Jazzicon(16, parseInt(account.slice(2, 10), 16)));
    }
  }, [account]);

  return (
    <div
      style={{
        height: "1rem",
        width: "1rem",
        borderRadius: "1.125rem",
        backgroundColor: "#CED0D9",
      }}
      ref={ref}
    />
  );
}
