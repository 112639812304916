import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 3, 1),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
      "& #cross": {
        visibility: "hidden",
      },
    },
  },
  anchor: {
    fontSize: "15px",
    fontWeight: 500,
    cursor: "pointer",
    color: "#5E0200",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default useStyles;
