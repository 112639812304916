import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    borderRadius: 5,
    boxShadow: "10px ",
  },
  gridstyle: {
    marginBottom: -10,
    marginTop: 20,

    [theme.breakpoints.down("lg")]: {
      marginLeft: 8,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  gridstyle2: {
    marginLeft: 20,
  },
  tab: {
    textAlign: "left",
    // fontFamily: "K2D",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",

    color: "rgba(0,0,0,1)",
  },
  parentTab: {
    textAlign: "left",
    padding: "10px 25px",
    overflow: "hidden",
  },
  parentTab2: {
    textAlign: "left",
    overflow: "hidden",
  },
  deposit: {
    marginLeft: -20,
    paddingTop: 50,

    whiteSpace: "nowrap",
    textAlign: "left",
    // fontFamily: "K2D",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    color: "rgba(112,112,112,1)",
    textTransform: "uppercase",
  },
  input: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      [theme.breakpoints.down("lg")]: {
        width: "40ch",
        marginLeft: -25,
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch",
        marginLeft: -15,
      },
      marginTop: 25,
    },
  },
  btn: {
    fontSize: 10,
    marginLeft: 2,
    // fontFamily: "K2D",
    fontStyle: "normal",
  },
  btn2: {
    backgroundColor: "#5E0200",
    color: "white",
    "&:hover": {
      background: "#5E0200",
    },
    // fontFamily: "K2D",
    fontStyle: "normal",
    fontSize: 14,
    padding: "5px 20px",
    marginTop: 10,
    borderRadius: 5,
  },
  tab2: {
    background: "#AAAAAA",
    border: "2px solid #AAAAAA",
    "&.Mui-selected": {
      background: "white",
      border: "0.5px solid #C1C1C1",
    },
  },
}));
export { useStyles };
