import { createSlice } from "@reduxjs/toolkit";
import { filterToKey } from "./utils";

// export interface LogsState {
//   [chainId: number]: {
//     [filterKey: string]: {
//       listeners: number
//       fetchingBlockNumber?: number
//       results?:
//         | {
//             blockNumber: number
//             logs: Log[]
//             error?: undefined
//           }
//         | {
//             blockNumber: number
//             logs?: undefined
//             error: true
//           }
//     }
//   }
// }

const slice = createSlice({
  name: "logs",
  initialState: {},
  reducers: {
    // : PayloadAction<{ chainId: number; filter: EventFilter }>
    addListener(state, { payload: { chainId, filter } }) {
      if (!state[chainId]) state[chainId] = {};
      const key = filterToKey(filter);
      if (!state[chainId][key])
        state[chainId][key] = {
          listeners: 1,
        };
      else state[chainId][key].listeners++;
    },

    // : PayloadAction<{ chainId: number; filters: EventFilter[]; blockNumber: number }>
    fetchingLogs(state, { payload: { chainId, filters, blockNumber } }) {
      if (!state[chainId]) return;
      for (const filter of filters) {
        const key = filterToKey(filter);
        if (!state[chainId][key]) continue;
        state[chainId][key].fetchingBlockNumber = blockNumber;
      }
    },

    // : PayloadAction<{ chainId: number; filter: EventFilter; results: { blockNumber: number; logs: Log[] } }>
    fetchedLogs(state, { payload: { chainId, filter, results } }) {
      if (!state[chainId]) return;
      const key = filterToKey(filter);
      const fetchState = state[chainId][key];
      if (
        !fetchState ||
        (fetchState.results &&
          fetchState.results.blockNumber > results.blockNumber)
      )
        return;
      fetchState.results = results;
    },

    // : PayloadAction<{ chainId: number; blockNumber: number; filter: EventFilter }>
    fetchedLogsError(state, { payload: { chainId, filter, blockNumber } }) {
      if (!state[chainId]) return;
      const key = filterToKey(filter);
      const fetchState = state[chainId][key];
      if (
        !fetchState ||
        (fetchState.results && fetchState.results.blockNumber > blockNumber)
      )
        return;
      fetchState.results = {
        blockNumber,
        error: true,
      };
    },

    // : PayloadAction<{ chainId: number; filter: EventFilter }>
    removeListener(state, { payload: { chainId, filter } }) {
      if (!state[chainId]) return;
      const key = filterToKey(filter);
      if (!state[chainId][key]) return;
      state[chainId][key].listeners--;
    },
  },
});

export const {
  addListener,
  removeListener,
  fetchedLogs,
  fetchedLogsError,
  fetchingLogs,
} = slice.actions;
export const logsReducer = slice.reducer;
