import ethereumLogoUrl from "../assets/images/ethereum-logo.png";

export const SupportedChainId = {
  // mainnet
  MAINNET: 1,

  // testnet
  RINKEBY: 4,
};

export const ALL_SUPPORTED_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.RINKEBY,
];

export const CHAIN_INFO = {
  [SupportedChainId.MAINNET]: {
    explorer: "https://etherscan.io/",
    label: "Ethereum",
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  },
  [SupportedChainId.RINKEBY]: {
    explorer: "https://rinkeby.etherscan.io/",
    label: "Rinkeby",
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: "Rinkeby ETH", symbol: "rinkETH", decimals: 18 },
  },
};
