import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import Web3ReactManager from "./components/Web3ReactManager/Web3ReactManager";

function App() {
  return (
    <div>
      <Navbar />
      <Web3ReactManager>
        <Body />
      </Web3ReactManager>
      <Footer />
    </div>
  );
}

export default App;
