import { useStyles } from "./styles";
import { Grid, Button } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import IdentIcon from "./IdentIcon";
import CopyHelper from "./CopyHelper";
import { getEtherscanLink, shortenAddress } from "../../../utils";
import { walletList } from "../../../constants/wallet";
import { injected, walletconnect, walletlink } from "../../../connectors";
import { ExternalLink as LinkIcon } from "react-feather";
import { ReactComponent as Cross } from "../../../assets/images/Cross.svg";
import WalletConnectIcon from "../../../assets/images/walletConnectIcon.svg";
import WalletLinkIcon from "../../../assets/images/coinbaseWalletIcon.svg";

export default function AccountDetails({
  toggleWalletModal = () => {},
  openOptions = () => {},
  tryDeactivation = () => {},
}) {
  const classes = useStyles();
  const { chainId, account, connector } = useWeb3React();

  function formatConnectorName() {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = walletList
      .filter(
        (option) =>
          option.connector === connector &&
          (connector !== injected ||
            isMetaMask === (option.name === "Metamask"))
      )
      .map((option) => option.name)[0];
    // console.log(name);
    return `Connected with ${name}`;
  }

  function getStatusIcon() {
    if (connector === injected) {
      return <IdentIcon />;
    } else if (connector === walletconnect) {
      return <img src={WalletConnectIcon} alt={""} />;
    } else if (connector === walletlink) {
      return <img src={WalletLinkIcon} alt={""} />;
    }
  }

  return (
    <Grid container>
      <Grid style={{ marginTop: 3, marginBottom: 15 }} item xs={11}>
        <strong>Account</strong>
      </Grid>
      <Grid item xs={1}>
        <Cross onClick={toggleWalletModal} style={{ cursor: "pointer" }} />
      </Grid>

      <Grid xs={12}>
        <div className={classes.paper3}>
          <Grid container>
            <Grid item xs={4} lg={5}>
              <span
                style={{
                  color: "rgb(110, 114, 125)",
                  fontSize: "0.825rem",
                }}
              >
                {formatConnectorName()}
              </span>
            </Grid>
            <Grid xs={0} lg={1} item></Grid>
            <Grid xs={0} lg={1} item></Grid>
            <Grid
              style={{
                float: "left",
                cursor: "pointer",
              }}
              item
              xs={5}
              lg={3}
            >
              {/* eslint-disable-next-line */}
              <a
                variant="outlined"
                type="button"
                className={classes.btnStyle}
                onClick={() => {
                  tryDeactivation();
                }}
              >
                disconnect
              </a>
            </Grid>
            <Grid
              style={{
                cursor: "pointer",
              }}
              item
              xs={3}
              lg={2}
            >
              {/* eslint-disable-next-line */}
              <a
                variant="outlined"
                type="button"
                className={classes.btnStyle}
                onClick={() => {
                  openOptions();
                }}
              >
                change
              </a>
            </Grid>
            <Grid style={{ marginTop: "13px" }} xs={1} item>
              {getStatusIcon()}
            </Grid>
            <Grid style={{ marginTop: "10px" }} xs={11}>
              <span style={{ fontSize: "20px", marginLeft: "10px" }}>
                {shortenAddress(account)}
              </span>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item xs={6}>
              <CopyHelper toCopy={account}>Copy Address</CopyHelper>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item xs={6}>
              <a
                className={classes.btnStyle2}
                href={getEtherscanLink(chainId, account, "address")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    color: "rgb(110, 114, 125)",
                  }}
                  className={classes.btnStyle2}
                  size="small"
                  startIcon={<LinkIcon size={16} />}
                >
                  View On Explorer
                </Button>
              </a>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {/* <Grid xs={12}>
        <div className={classes.paper2}>
          <Grid container>
            <Grid xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      paddingTop: 1,
                    }}
                  >
                    Your Transactions Will Appear Here...
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={1}></Grid>
          </Grid>
        </div>
      </Grid> */}
    </Grid>
  );
}
