import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { calculateGasMargin, getSigner, toWei } from "../utils";
import { useSelectedContract } from "./useContract";
import { SupportedTokens } from "../constants/tokens";

export function useStakeCallback(tokenName) {
  const { chainId, library, account } = useWeb3React();
  const contract = useSelectedContract(tokenName);

  return useCallback(
    async (amount) => {
      if (!chainId) {
        console.error("no chainId");
        return;
      }

      if (!contract) {
        console.error("contract is null");
        return;
      }

      if (!amount) {
        console.error("amount is null");
        return;
      }

      const signer = getSigner(library, account);
      let response;

      if (tokenName === SupportedTokens.ETH) {
        const estimatedGas = await contract
          .connect(signer)
          .estimateGas.stake({ value: toWei({ num: amount }) });

        // actual txn
        response = await contract.connect(signer).stake({
          gasLimit: calculateGasMargin(estimatedGas),
          value: toWei({ num: amount }),
        });
      } else {
        const estimatedGas = await contract
          .connect(signer)
          .estimateGas.stake(toWei({ num: amount }));

        // actual txn
        response = await contract
          .connect(signer)
          .stake(toWei({ num: amount }), {
            gasLimit: calculateGasMargin(estimatedGas),
          });
      }

      // console.log("response >>> ", response);
      return response;
    },
    [chainId, contract, library, account, tokenName]
  );
}
