import { ZERO_ADDRESS } from ".";
import {
  DFCH_TOKEN_CONTRACT_ADDRESSES,
  SHIB_TOKEN_CONTRACT_ADDRESSES,
  DFCH_STAKING_CONTRACT_ADDRESSES,
  SHIB_STAKING_CONTRACT_ADDRESSES,
  ETH_STAKING_CONTRACT_ADDRESSES,
} from "./addresses";
import blackLogo from "../assets/logo/black-logo.svg";

export const AppState = {
  ETH: "ETH",
  TOKEN: "TOKEN",
};

export const SupportedTokens = {
  ETH: "ETH",
  DFCH: "DFCH",
  SHIB: "SHIB",
};

export const TOKEN_INFO = {
  [SupportedTokens.ETH]: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    logoUrl: "https://cdn.freelogovectors.net/svg05/ethereum-logo.svg",
    apy: "12",
  },
  [SupportedTokens.DFCH]: {
    name: "DFCH",
    symbol: "DFCH",
    decimals: 18,
    logoUrl: blackLogo,
    apy: "30",
  },
  [SupportedTokens.SHIB]: {
    name: "SHIB",
    symbol: "SHIB",
    decimals: 18,
    logoUrl: "https://shibatoken.com/images/shib-logo.svg",
    apy: "8",
  },
};

// returns zero address in case of ETh
export const getTokenAddress = (chainId, tokenName = SupportedTokens.ETH) => {
  if (tokenName === SupportedTokens.DFCH)
    return DFCH_TOKEN_CONTRACT_ADDRESSES[chainId];
  if (tokenName === SupportedTokens.SHIB)
    return SHIB_TOKEN_CONTRACT_ADDRESSES[chainId];

  return ZERO_ADDRESS;
};

// returns zero address in case of ETh
export const getStakingAddress = (chainId, tokenName = SupportedTokens.ETH) => {
  if (tokenName === SupportedTokens.DFCH)
    return DFCH_STAKING_CONTRACT_ADDRESSES[chainId];
  if (tokenName === SupportedTokens.SHIB)
    return SHIB_STAKING_CONTRACT_ADDRESSES[chainId];

  return ETH_STAKING_CONTRACT_ADDRESSES[chainId];
};
