import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    updated: false,
  },
  reducers: {
    updateUserState: (state) => {
      state.updated = !state.updated;
    },
  },
});

export const { updateUserState } = userSlice.actions;
export const userReducer = userSlice.reducer;
