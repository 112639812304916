import { makeStyles } from "@material-ui/core/styles";
import FooterImage from "../../assets/images/footer.svg";
import FooterImageMobile from "../../assets/images/FooterImage.svg";
const useStyles = makeStyles((theme) => ({
  // fontFamily: "K2D",
  backgroundImg: {
    [theme.breakpoints.down("xl")]: {
      marginTop: 200,
      backgroundImage: `url(  ${FooterImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "500px",
      backgroundPositionY: "0.1em",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 100,
      backgroundImage: `url(  ${FooterImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "500px",
      backgroundPositionY: "0.1em",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 100,
      backgroundImage: `url(  ${FooterImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "500px",
      backgroundPositionY: "0.1em",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(  ${FooterImageMobile})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 250,
      backgroundRepeat: "no-repeat",
    },
  },
  list: {
    [theme.breakpoints.down("xl")]: {
      listStyleType: "square",
      textAlign: "start",
      color: "white",
      fontSize: 11,
      lineHeight: "150%",
    },
    [theme.breakpoints.down("lg")]: {
      listStyleType: "square",
      textAlign: "start",
      color: "white",
      fontSize: 11,
      lineHeight: "150%",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      color: "white",
      marginTop: 0,
      fontSize: 11,
      lineHeight: "150%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: 0,
    },
  },
  list2: {
    [theme.breakpoints.down("xl")]: {
      listStyleType: "square",
      textAlign: "start",
      color: "white",
      fontSize: 11,
      lineHeight: "270%",
    },
    [theme.breakpoints.down("lg")]: {
      listStyleType: "square",
      textAlign: "start",
      color: "white",
      fontSize: 11,
      lineHeight: "270%",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      color: "white",
      fontSize: 13,
      marginTop: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 0,
      lineHeight: "170%",
    },
  },
  listItem: {
    [theme.breakpoints.down("sm")]: {
      listStyle: "none",
      marginRight: 22,
      fontSize: 10,
    },
  },

  grid: {
    [theme.breakpoints.down("xl")]: {
      marginTop: 260,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 260,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      textAlign: "center",
    },
  },
  logo: {
    [theme.breakpoints.up("xl")]: {
      marginTop: 70,
    },
    [theme.breakpoints.up(1500)]: {
      marginTop: 70,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 80,
    },
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },

  typography: {
    fontSize: 11,
    color: "rgba(255,255,255,1)",
    marginTop: -10,
    [theme.breakpoints.down("xl")]: {
      textAlign: "start",
    },
    [theme.breakpoints.down("lg")]: {
      textAlign: "start",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  listTypography: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
      color: "rgba(246,39,23,1)",
      textAlign: "start",
      marginTop: 40,
      marginLeft: 30,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
      color: "rgba(246,39,23,1)",
      textAlign: "start",
      marginTop: 30,
      marginLeft: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      color: "rgba(246,39,23,1)",
      textAlign: "center",
      marginTop: -5,
      marginLeft: -5,
    },
  },
  dot: {
    height: "23px",
    width: "23px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 10,
    marginTop: 20,
    backgroundPosition: "fixed",
  },
  footBottom: {
    [theme.breakpoints.down("xl")]: {
      marginTop: 30,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  fbottom2: {
    color: "rgba(255,255,255,1)",
    [theme.breakpoints.down("xl")]: {
      marginTop: 20,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      fontSize: 11,
    },
  },
}));

export { useStyles };
