import { useWeb3React } from "@web3-react/core";
import { useCallback, useMemo } from "react";
import { MAX_UINT256 } from "../constants";
import { useTokenAllowance } from "../data/token";
import { calculateGasMargin, getSigner, toWei } from "../utils";
import { useTokenContract } from "./useContract";

export const ApprovalState = {
  UNKNOWN: "UNKNOWN",
  NOT_APPROVED: "NOT_APPROVED",
  APPROVED: "APPROVED",
};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(tokenAddress, amountToApprove, spender) {
  const { library, account, chainId } = useWeb3React();

  amountToApprove = useMemo(
    () => toWei({ num: amountToApprove }),
    [amountToApprove]
  );

  const currentAllowance = useTokenAllowance(
    tokenAddress,
    account ?? undefined,
    spender
  );

  // check the current approval status
  const approvalState = useMemo(() => {
    if (!tokenAddress || !amountToApprove || !spender)
      return ApprovalState.UNKNOWN;

    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [tokenAddress, amountToApprove, spender, currentAllowance]);

  const tokenContract = useTokenContract(tokenAddress, false);

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }

    if (!chainId) {
      console.error("no chainId");
      return;
    }

    if (!tokenContract) {
      console.error("tokenContract is null");
      return;
    }

    if (!amountToApprove) {
      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      console.error("no spender");
      return;
    }

    const signer = getSigner(library, account);

    let useExact = false;
    const estimatedGas = await tokenContract
      .connect(signer)
      .estimateGas.approve(spender, MAX_UINT256)
      .catch(() => {
        // general fallback for tokens who restrict approval amounts
        useExact = true;
        return tokenContract
          .connect(signer)
          .estimateGas.approve(spender, amountToApprove);
      });

    // actual txn
    return tokenContract
      .connect(signer)
      .approve(spender, useExact ? amountToApprove : MAX_UINT256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.debug("Failed to approve token", error);
        throw error;
      });
  }, [
    approvalState,
    tokenContract,
    spender,
    amountToApprove,
    chainId,
    account,
    library,
  ]);

  return [approvalState, approve];
}
