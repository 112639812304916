import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 3, 1),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
      "& #cross": {
        visibility: "hidden",
      },
    },
  },

  btnStyle: {
    borderRadius: "13px",
    border: "1px solid #5E0200",
    padding: "5px",
    fontSize: "14px",
    color: "#5E0200",
    "&:hover": {
      border: "1px solid #5E0200",

      textDecoration: "underline",
    },
  },

  btnStyle2: {
    "&:hover": {
      textDecoration: "underline",
    },
  },

  svgStyle: {
    borderRadius: "50px",
    overflow: "hidden",
    padding: "0px",
    margin: "0px",
    height: "14px",
    display: "inline-block",
    background: "rgb(249, 100, 1) none repeat scroll 0% 0%",
  },

  tryagain: {
    borderRadius: "8px",
  },

  paper3: {
    backgroundColor: "white",
    padding: theme.spacing(2, 4, 1.7),
    paddingLeft: 20,
    borderRadius: theme.spacing(1.5),
    border: "1px solid rgb(206, 208, 217)",
    marginBottom: 12,

    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },
  paper2: {
    backgroundColor: "rgb(237, 238, 242)",
    padding: theme.spacing(2, 4, 1.7),
    paddingLeft: 20,
    borderRadius: theme.spacing(1.5),
    border: "1px solid rgb(206, 208, 217)",
    marginBottom: 12,

    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },
}));
export { useStyles };
