import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { addPopup, removePopup, toggleWalletModal } from "./slice";

// returns blockNumber
export function useBlockNumber() {
  const { chainId } = useWeb3React();

  return useSelector((state) => state.application.blockNumber[chainId ?? -1]);
}

// returns boolean value
export function useWalletModalOpen() {
  return useSelector((state) => state.application.walletModalOpen);
}

// toggles the modal open/close
export function useWalletModalToggle() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleWalletModal()), [dispatch]);
}

// returns a function that allows adding a popup
export function useAddPopup() {
  const dispatch = useDispatch();

  return useCallback(
    (content, key = "") => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup() {
  const dispatch = useDispatch();
  return useCallback(
    (key) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups() {
  const list = useSelector((state) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
