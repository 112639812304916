import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  balance: {
    // fontFamily: "K2D",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    color: "rgba(25,20,34,1)",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 100,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  margin: {
    [theme.breakpoints.down("lg")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  margin2: {
    marginTop: 20,
  },
  card_paper: {
    padding: 4,

    backgroundColor: "#5E0200",
  },
}));

export { useStyles };
