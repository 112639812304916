import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "../utils";
import { ZERO_ADDRESS } from "../constants";
import {
  ETH_STAKING_CONTRACT_ADDRESSES,
  DFCH_STAKING_CONTRACT_ADDRESSES,
  SHIB_STAKING_CONTRACT_ADDRESSES,
  DFCH_TOKEN_CONTRACT_ADDRESSES,
  SHIB_TOKEN_CONTRACT_ADDRESSES,
  MULTICALL_CONTRACT_ADDRESSES,
} from "../constants/addresses";
import ETHStakingABI from "../constants/abis/eth-staking.json";
import TokenStakingABI from "../constants/abis/token-staking.json";
import MulticallABI from "../constants/abis/multicall.json";
import TokenABI from "../constants/abis/token.json";
import { SupportedTokens } from "../constants/tokens";

// returns null on errors
export const useContract = (address, ABI, withSignerIfPossible) => {
  const { library, account } = useWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [library, account, address, ABI, withSignerIfPossible]);
};

export const useTokenContract = (address, withSignerIfPossible = true) => {
  return useContract(
    address !== ZERO_ADDRESS && address,
    TokenABI,
    withSignerIfPossible
  );
};
export const useTokenStakingContract = (
  address,
  withSignerIfPossible = true
) => {
  return useContract(
    address !== ZERO_ADDRESS && address,
    TokenStakingABI,
    withSignerIfPossible
  );
};

// Multicall contract
export const useMulticallContract = () => {
  const { chainId } = useWeb3React();
  return useContract(
    MULTICALL_CONTRACT_ADDRESSES[chainId],
    MulticallABI,
    false
  );
};

// Token Contracts
export const useDFCHTokenContract = () => {
  const { chainId } = useWeb3React();
  return useTokenContract(DFCH_TOKEN_CONTRACT_ADDRESSES[chainId]);
};

export const useSHIBTokenContract = () => {
  const { chainId } = useWeb3React();
  return useTokenContract(SHIB_TOKEN_CONTRACT_ADDRESSES[chainId]);
};

// Staking contracts
export const useETHStakingContract = () => {
  const { chainId } = useWeb3React();
  return useContract(
    ETH_STAKING_CONTRACT_ADDRESSES[chainId],
    ETHStakingABI,
    true
  );
};

export const useDFCHStakingContract = () => {
  const { chainId } = useWeb3React();
  return useTokenStakingContract(DFCH_STAKING_CONTRACT_ADDRESSES[chainId]);
};

export const useSHIBStakingContract = () => {
  const { chainId } = useWeb3React();
  return useTokenStakingContract(SHIB_STAKING_CONTRACT_ADDRESSES[chainId]);
};

export const useSelectedContract = (tokenName = SupportedTokens.ETH) => {
  const ethStakingContract = useETHStakingContract();
  const dfchStakingContract = useDFCHStakingContract();
  const shibStakingContract = useSHIBStakingContract();

  return useMemo(() => {
    if (tokenName === SupportedTokens.ETH) return ethStakingContract;
    if (tokenName === SupportedTokens.DFCH) return dfchStakingContract;
    if (tokenName === SupportedTokens.SHIB) return shibStakingContract;
  }, [tokenName, ethStakingContract, dfchStakingContract, shibStakingContract]);
};
