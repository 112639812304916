import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 2, 2),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },
  paper2: {
    backgroundColor: "rgb(237, 238, 242)",
    padding: theme.spacing(2, 2, 1.7),
    borderRadius: theme.spacing(1.5),
    marginBottom: 12,
    border: "1px solid rgb(206, 208, 217)",

    "&:hover": {
      border: "1px solid red",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },

  btn: {
    borderRadius: 50,
    border: "3px dotted white",
    color: "white",
    padding: "10px 24px",
    // fontFamily: "K2D",
    fontSize: "11px",
    "&:hover": {
      background: "#fff",
      color: "#000",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "10px 24px",
      fontSize: "11px",
      marginRight: 40,
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px 18px",
      fontSize: "9px",
      marginRight: 20,
    },

    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      fontSize: "9px",
      marginRight: 0,
    },
  },
  tryagain: {
    borderRadius: "8px",
  },

  paper3: {
    backgroundColor: "white",
    padding: theme.spacing(2, 4, 1.7),
    paddingLeft: 20,
    borderRadius: theme.spacing(1.5),
    border: "1px solid rgb(206, 208, 217)",
    marginBottom: 12,

    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "280px",
    },
  },
}));

export { useStyles };
