import { Button } from "@material-ui/core";
import React from "react";
import { CheckCircle, Copy } from "react-feather";
import useCopyClipboard from "../../../hooks/useCopyClipboard";

export default function CopyHelper({ toCopy, children }) {
  const [isCopied, setCopied] = useCopyClipboard();

  return (
    <Button
      style={{ backgroundColor: "transparent", color: "rgb(110, 114, 125)" }}
      size="small"
      startIcon={isCopied ? <CheckCircle size={"16"} /> : <Copy size={"16"} />}
      onClick={() => setCopied(toCopy)}
    >
      {isCopied ? "copied" : children}
    </Button>
  );
}
