import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useTokenContract } from "../../hooks/useContract";
import { NEVER_RELOAD, useSingleCallResult } from "../../redux/multicall/hooks";

export function useTokenAllowance(tokenAddress, owner, spender) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const inputs = useMemo(() => [owner, spender], [owner, spender]);
  const allowanceCall = useSingleCallResult(contract, "allowance", inputs);
  // console.log("allowanceCall >>> ", allowanceCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (allowanceCall.loading) return null;
    if (allowanceCall.result) {
      return allowanceCall.result?.[0];
    }
    return undefined;
  }, [chainId, allowanceCall]);
}

export function useTokenBalance(tokenAddress = null, owner) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const inputs = useMemo(() => [owner], [owner]);
  const balanceCall = useSingleCallResult(contract, "balanceOf", inputs);
  // console.log("balanceCall >>> ", balanceCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (balanceCall.loading) return null;
    if (balanceCall.result) {
      return balanceCall.result?.[0];
    }
    return undefined;
  }, [chainId, balanceCall]);
}

export function useTokenSymbol(tokenAddress) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const symbolCall = useSingleCallResult(
    contract,
    "symbol",
    undefined,
    NEVER_RELOAD
  );
  // console.log("symbolCall >>> ", symbolCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (symbolCall.loading) return null;
    if (symbolCall.result) {
      return symbolCall.result?.[0];
    }
    return undefined;
  }, [chainId, symbolCall]);
}

export function useTokenDecimals(tokenAddress) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const decimalsCall = useSingleCallResult(
    contract,
    "decimals",
    undefined,
    NEVER_RELOAD
  );
  // console.log("decimalsCall >>> ", decimalsCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (decimalsCall.loading) return null;
    if (decimalsCall.result) {
      return Number(decimalsCall.result?.[0]);
    }
    return undefined;
  }, [chainId, decimalsCall]);
}

export function useTokenTotalSupply(tokenAddress) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const totalSupplyCall = useSingleCallResult(contract, "totalSupply");
  // console.log("totalSupplyCall >>> ", totalSupplyCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (totalSupplyCall.loading) return null;
    if (totalSupplyCall.result) {
      return totalSupplyCall.result?.[0];
    }
    return undefined;
  }, [chainId, totalSupplyCall]);
}

export function useTokenName(tokenAddress) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(tokenAddress, false);

  const nameCall = useSingleCallResult(
    contract,
    "name",
    undefined,
    NEVER_RELOAD
  );
  // console.log("nameCall >>> ", nameCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (nameCall.loading) return null;
    if (nameCall.result) {
      return nameCall.result?.[0];
    }
    return undefined;
  }, [chainId, nameCall]);
}
