// export interface EventFilter {
//   address?: string
//   topics?: Array<string | Array<string> | null>
// }

// export interface Log {
//   topics: Array<string>
//   data: string
//   transactionIndex: number
//   logIndex: number
//   blockNumber: number
// }

/**
 * Converts a filter to the corresponding string key
 * @param filter (EventFilter) the filter to convert
 * returns string
 */
export function filterToKey(filter) {
  return `${filter.address ?? ""}:${
    filter.topics
      ?.map((topic) =>
        topic ? (Array.isArray(topic) ? topic.join(";") : topic) : "\0"
      )
      ?.join("-") ?? ""
  }`;
}

/**
 * Convert a filter key to the corresponding filter
 * @param key (string) key to convert
 * returns EventFilter
 */
export function keyToFilter(key) {
  const pcs = key.split(":");
  const address = pcs[0];
  const topics = pcs[1].split("-").map((topic) => {
    if (topic === "\0") return null;
    const parts = topic.split(";");
    if (parts.length === 1) return parts[0];
    return parts;
  });

  return {
    address: address.length === 0 ? undefined : address,
    topics,
  };
}
