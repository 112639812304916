import { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Balance from "../Balance/Balance";
import FullWidthTabs from "../Tab/Tab";
import CryptoCard from "../CryptoCard/CryptoCard";
import { useStyles } from "./styles";
import { TOKEN_INFO } from "../../constants/tokens";

export default function Body() {
  const classes = useStyles();
  const [tokenName, setTokenName] = useState("ETH");

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container style={{ justifyContent: "space-between" }}>
                  {Object.values(TOKEN_INFO).map((info, i) => (
                    <CryptoCard
                      key={i}
                      name={info.name}
                      setter={setTokenName}
                      img={info.logoUrl}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Balance tokenName={tokenName} />
              </Grid>
              <Grid className={classes.margin2} item xs={12}>
                <FullWidthTabs tokenName={tokenName} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
