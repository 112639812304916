import React from "react";
import { Grid as MuiGrid } from "@material-ui/core";
import { useStyles } from "./styles";
import { Circle } from "react-feather";

export default function Option({
  name,
  icon,
  link = null,
  active = false,
  onClick = () => {},
}) {
  const classes = useStyles();

  const GridWrapper = ({ children }) => (
    <MuiGrid item xs={12}>
      <div onClick={onClick} className={classes.paper2}>
        {children}
      </div>
    </MuiGrid>
  );

  const content = (
    <MuiGrid container>
      <MuiGrid item xs={8}>
        <p
          style={{
            fontSize: 18,
            margin: 0,
            paddingTop: 1,
          }}
        >
          {active ? (
            <Circle
              style={{ backgroundColor: "green", borderRadius: "50%" }}
              size={10}
              color="green"
            />
          ) : (
            ""
          )}
          &nbsp;
          {name}
        </p>
      </MuiGrid>
      <MuiGrid item xs={4}>
        <img
          style={{ float: "right", marginBottom: 5 }}
          width="25px"
          src={icon}
          alt={`${name}-icon`}
        />
      </MuiGrid>
    </MuiGrid>
  );

  if (link) {
    return (
      <GridWrapper>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          {content}
        </a>
      </GridWrapper>
    );
  }

  return <GridWrapper>{content}</GridWrapper>;
}
