import { createSlice } from "@reduxjs/toolkit";
import { toCallKey } from "./utils";

// export interface MulticallState {
//   callListeners?: {
//     // on a per-chain basis
//     [chainId: number]: {
//       // stores for each call key the listeners' preferences
//       [callKey: string]: {
//         // stores how many listeners there are per each blocks per fetch preference
//         [blocksPerFetch: number]: number
//       }
//     }
//   }
//   callResults: {
//     [chainId: number]: {
//       [callKey: string]: {
//         data?: string | null
//         blockNumber?: number
//         fetchingBlockNumber?: number
//       }
//     }
//   }
// }

const multicallSlice = createSlice({
  name: "multicallSlice",
  initialState: {
    callResults: {},
  },
  reducers: {
    addMulticallListeners: (
      state,
      {
        payload: {
          calls,
          chainId,
          options: { blocksPerFetch },
        },
      }
    ) => {
      // MulticallState['callListeners']
      const listeners = state.callListeners
        ? state.callListeners
        : (state.callListeners = {});
      listeners[chainId] = listeners[chainId] ?? {};
      calls.forEach((call) => {
        const callKey = toCallKey(call);
        listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
        listeners[chainId][callKey][blocksPerFetch] =
          (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
      });
    },

    removeMulticallListeners: (
      state,
      {
        payload: {
          chainId,
          calls,
          options: { blocksPerFetch },
        },
      }
    ) => {
      // MulticallState['callListeners']
      const listeners = state.callListeners
        ? state.callListeners
        : (state.callListeners = {});

      if (!listeners[chainId]) return;
      calls.forEach((call) => {
        const callKey = toCallKey(call);
        if (!listeners[chainId][callKey]) return;
        if (!listeners[chainId][callKey][blocksPerFetch]) return;

        if (listeners[chainId][callKey][blocksPerFetch] === 1) {
          delete listeners[chainId][callKey][blocksPerFetch];
        } else {
          listeners[chainId][callKey][blocksPerFetch]--;
        }
      });
    },

    fetchingMulticallResults: (
      state,
      { payload: { chainId, fetchingBlockNumber, calls } }
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach((call) => {
        const callKey = toCallKey(call);
        const current = state.callResults[chainId][callKey];
        if (!current) {
          state.callResults[chainId][callKey] = {
            fetchingBlockNumber,
          };
        } else {
          if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber) return;
          state.callResults[chainId][callKey].fetchingBlockNumber =
            fetchingBlockNumber;
        }
      });
    },

    errorFetchingMulticallResults: (
      state,
      { payload: { fetchingBlockNumber, chainId, calls } }
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach((call) => {
        const callKey = toCallKey(call);
        const current = state.callResults[chainId][callKey];
        if (!current || typeof current.fetchingBlockNumber !== "number") return; // only should be dispatched if we are already fetching
        if (current.fetchingBlockNumber <= fetchingBlockNumber) {
          delete current.fetchingBlockNumber;
          current.data = null;
          current.blockNumber = fetchingBlockNumber;
        }
      });
    },

    updateMulticallResults: (
      state,
      { payload: { chainId, results, blockNumber } }
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      Object.keys(results).forEach((callKey) => {
        const current = state.callResults[chainId][callKey];
        if ((current?.blockNumber ?? 0) > blockNumber) return;
        state.callResults[chainId][callKey] = {
          data: results[callKey],
          blockNumber,
        };
      });
    },
  },
});

export const {
  addMulticallListeners,
  removeMulticallListeners,
  fetchingMulticallResults,
  errorFetchingMulticallResults,
  updateMulticallResults,
} = multicallSlice.actions;
export const multicallReducer = multicallSlice.reducer;
