import { createSlice, nanoid } from "@reduxjs/toolkit";

// export interface ApplicationState {
//   readonly blockNumber: { readonly [chainId: number]: number }
//   readonly chainConnectivityWarning: boolean
//   readonly chainId: null
//   readonly walletModalOpen: ApplicationModal | null
//   readonly popupList: PopupList
// }

const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState: {
    blockNumber: {},
    chainId: null,
    popupList: [],
    walletModalOpen: false,
  },
  reducers: {
    updateChainId: (state, action) => {
      const { chainId } = action.payload;
      state.chainId = chainId;
    },

    updateBlockNumber: (state, action) => {
      const { chainId, blockNumber } = action.payload;
      if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId]
        );
      }
    },

    toggleWalletModal: (state) => {
      state.walletModalOpen = !state.walletModalOpen;
    },

    addPopup: (state, { payload: { content, key, removeAfterMs = 25000 } }) => {
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },

    removePopup: (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    },
  },
});

export const {
  updateChainId,
  updateBlockNumber,
  toggleWalletModal,
  addPopup,
  removePopup,
} = applicationSlice.actions;
export const applicationReducer = applicationSlice.reducer;
